import React from "react";
import {Link} from "gatsby";
import Layout from "../components/layout"
import {getCurrentLocale, getTranslatedValue} from "../helpers/fields";

const {getPageSlug} = require("../helpers/slugs");

class Pages extends React.Component {
    render() {

        const pages = this.props.pageContext.data;

        const locale = getCurrentLocale(this);

        const translations = this.props.pageContext.translations;

        return (
            <Layout page={this} translations={translations} lang={locale} className='kunstwerken'
                    title={getTranslatedValue(this, translations.information)} includeMenu={true}>
                <ul className="lijst-overzicht">
                    {pages.map((page, index) => {
                        return (
                            <li key={index}><Link to={getPageSlug(page.node, locale)}>
                                {getTranslatedValue(this, page.node.fieldValues.title)}
                            </Link></li>
                        )
                    })}
                </ul>
            </Layout>
        );
    }
}

export default Pages;
